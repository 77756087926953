import { render, staticRenderFns } from "./ClientUpdate.vue?vue&type=template&id=b13e3360"
import script from "./ClientUpdate.vue?vue&type=script&lang=js"
export * from "./ClientUpdate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_lodash@4.17.21_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports