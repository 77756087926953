<template>
  <v-row>
    <v-col>
      <tour tourKey="item_add_recommendation" />
      <v-row v-if="!isInaccessible && item.item_scores.length == 12">
        <v-col><strong>UK HSE</strong></v-col>
        <v-col
          >Material Assessment Score: {{ getMaterialScore("UK HSE").toFixed(2) }}</v-col
        >
        <v-col :class="demolitionScoring() ? 'text-decoration-line-through' : ''"
          >Priority Assessment Score: {{ Math.ceil(priorityScore.toFixed(2)) }}</v-col
        >
        <v-col>Total Score: {{ getTotalScore("UK HSE").toFixed(2) }}</v-col>
        <v-col>Risk: {{ getRiskRating("UK HSE") }}</v-col>
      </v-row>
      <v-divider />
      <v-row v-if="!isInaccessible && item.item_scores.length == 12">
        <v-col><strong>NZ</strong></v-col>
        <v-col>Material Assessment Score: {{ getMaterialScore("NZ").toFixed(2) }}</v-col>
        <v-col :class="demolitionScoring() ? 'text-decoration-line-through' : ''"
          >Priority Assessment Score: {{ Math.ceil(priorityScore.toFixed(2)) }}</v-col
        >
        <v-col>Total Score: {{ getTotalScore("NZ").toFixed(2) }}</v-col>
        <v-col>Risk: {{ getRiskRating("NZ") }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>
            <span class="recommendation-step"> Choose Actions </span>
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 ma-0">
          <v-item-group
            :mandatory="mandatory"
            :multiple="multiple"
            v-model="itemRecommendations"
          >
            <v-item
              v-slot:default="{ active, toggle }"
              v-for="(recommendation, i) of clientRecommendations"
              :key="recommendation.id"
              :value="recommendation.id"
            >
              <v-card
                outlined
                :color="active ? 'primary' : getColour(recommendation)"
                :class="`${active ? 'white--text' : ''} mt-2`"
                :data-cy="`recommendation-${i}`"
                @click="toggle"
              >
                <v-row class="my-2">
                  <v-col lg="2" md="3" sm="3" class="align-self-center">
                    <p class="pl-4 ma-0 text-left">{{ recommendation.name }}</p>
                    <v-tooltip
                      top
                      v-if="
                        item.assessment_method_id == 2 &&
                        sampledAsItem &&
                        sampledAsRecommendation(recommendation.id)
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon color="warning" class="float-right mt-n6" v-on="on"
                          >far fa-exclamation-triangle</v-icon
                        >
                      </template>
                      <span>Sampled as recommendation</span>
                    </v-tooltip>
                  </v-col>
                  <v-col lg="10" md="9" sm="7" class="align-self-center">
                    <p class="ma-0">
                      {{
                        recommendation.description != ""
                          ? recommendation.description
                          : "No description"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row v-if="noRecommendation">
        <v-col class="pa-2 warning center-text">
          <p class="pt-2">Please select a recommendation</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tour from "./Tour";
export default {
  props: ["aItem", "noRecommendation", "sampledAsItem"],
  components: { Tour },
  data: () => ({
    mandatory: false,
    multiple: true,
  }),
  created() {
    this.DEMOLITION = 2;
    this.NZDF_REFURBISHMENT = 5;
  },
  mounted() {
    if (this.isInaccessible) {
      this.itemRecommendations = [7];
    }

    if (this.building.report_type_id == 2 && !this.$route.params.item) {
      this.itemRecommendations.push(5);
    }
  },
  methods: {
    ...mapActions(["getAsbestosRecommendations", "getItems"]),
    sampledAsRecommendation(recommendation) {
      if (!this.sampledAsItem) return false;
      return this.sampledAsItem.item_recommendations.find(
        (itemRecommendation) => itemRecommendation == recommendation
      );
    },
    getColour({ id }) {
      if (
        this.sampledAs.id &&
        this.sampledAs.item_recommendations.find((recommendation) => recommendation == id)
      ) {
        return "blue lighten-4";
      }
    },
    demolitionScoring() {
      return (
        (this.building.report_type_id == this.DEMOLITION ||
          this.building.report_type_id == this.NZDF_REFURBISHMENT) &&
        !this.building.is_answering_questions
      );
    },
    //If changed update backend item model
    getAmountScore() {
      const M2 = 1;
      const AMOUNT = 2;
      const LINEAR = 3;
      const UNKNOWN = 4;
      switch (this.item.amount_type_id) {
        case M2:
        case LINEAR:
          if (this.item.amount <= 0.5) return 0;
          else if (this.item.amount <= 10) return 1;
          else if (this.item.amount <= 50) return 2;
          return 3;
        case UNKNOWN:
          return 3;
        case AMOUNT:
          return this.item.amount > 0 ? 1 : 0;
      }
    },
    getLikelihoodScore() {
      let likelihoodGroup = this.asbestos.find((group) => group.id == 3);
      let location = likelihoodGroup.properties.find((property) => property.id == 6);
      let locationScore = location.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let accessibility = likelihoodGroup.properties.find((property) => property.id == 7);
      let accessibilityScore = accessibility.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let amount = this.getAmountScore();
      return (locationScore[0].score + accessibilityScore[0].score + amount) / 3;
    },
    getActivityScore() {
      let activityGroup = this.asbestos.find((group) => group.id == 2);
      let activityProperty = activityGroup.properties.find(
        (property) => property.id == 5
      );
      let activityScore = activityProperty.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      return activityScore[0].score;
    },
    getExposureScore() {
      let exposureGroup = this.asbestos.find((group) => group.id == 4);
      let occupancy = exposureGroup.properties.find((property) => property.id == 8);
      let occupancyScore = occupancy.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let frequency = exposureGroup.properties.find((property) => property.id == 9);
      let frequencyScore = frequency.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let usage = exposureGroup.properties.find((property) => property.id == 10);
      let usageScore = usage.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      return (
        (occupancyScore[0].score + frequencyScore[0].score + usageScore[0].score) / 3
      );
    },
    getMaintenanceScore() {
      let maintenanceGroup = this.asbestos.find((group) => group.id == 5);
      let type = maintenanceGroup.properties.find((property) => property.id == 11);
      let typeScore = type.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let activity = maintenanceGroup.properties.find((property) => property.id == 12);
      let activityScore = activity.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      return (typeScore[0].score + activityScore[0].score) / 2;
    },
    getPriorityScore() {
      return (
        this.getActivityScore() +
        this.getLikelihoodScore() +
        this.getExposureScore() +
        this.getMaintenanceScore()
      );
    },
    getMaterialScore(scoringType = "UK HSE") {
      if (this.isInaccessible || this.item.is_negative) return 0;
      let priorityGroup = this.asbestos.find((group) => group.id == 1);
      let productType = priorityGroup.properties.find((property) => property.id == 1);
      let productScore = productType.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let extentDamage = priorityGroup.properties.find((property) => property.id == 2);
      let extentScore = extentDamage.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let surfaceTreatment = priorityGroup.properties.find(
        (property) => property.id == 3
      );
      let surfaceScore = surfaceTreatment.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let sampleScore = 2;
      let statusId = null;
      statusId = this.item;
      const statuses = this.sampleStatuses;
      sampleScore =
        scoringType == "UK HSE"
          ? statuses.find((status) => status.id == statusId || 1).score
          : 0;
      return (
        productScore[0].score + extentScore[0].score + surfaceScore[0].score + sampleScore
      );
    },
    getTotalScore(scoringType = "UK HSE") {
      if (this.isInaccessible || this.item.is_negative) return 0;
      if (this.demolitionScoring()) return Math.ceil(this.getMaterialScore(scoringType));
      return Math.ceil(this.getMaterialScore(scoringType) + this.getPriorityScore());
    },
    getRiskRating(scoringType = "UK HSE") {
      const DEMOLITION_REPORT = 2;
      const INACCESSIBLE = 5;
      const NEGATIVE = 2;
      const RISK_VERY_LOW = 7;
      const RISK_LOW = 12;
      const RISK_NZ_LOW = 7;
      const RISK_NZ_MEDIUM = 14;
      const RISK_MODERATE = 18;
      const DEMOLITION_RISK_NZ_LOW = 3;
      const DEMOLITION_RISK_NZ_MEDIUM = 6;
      const DEMOLITION_RISK_VERY_LOW = 4;
      const DEMOLITION_RISK_LOW = 6;
      const DEMOLITION_RISK_MODERATE = 9;
      let demolition =
        !this.building.is_answering_questions &&
        this.building.report_type_id == DEMOLITION_REPORT;

      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return "Negative";

      if (this.item.assessment_method_id == INACCESSIBLE) {
        return "Inaccessible";
      }
      if (
        this.item.sample &&
        this.item.sample.status &&
        this.item.sample.status.id == NEGATIVE
      ) {
        return "Negative";
      }
      let totalScore = this.getTotalScore();
      if (totalScore == 0) {
        return "Negative";
      }
      if (scoringType == "NZ") {
        if (demolition) {
          return totalScore <= DEMOLITION_RISK_NZ_LOW
            ? "Low"
            : totalScore <= DEMOLITION_RISK_NZ_MEDIUM
            ? "Medium"
            : "High";
        }
        return totalScore <= RISK_NZ_LOW
          ? "Low"
          : totalScore <= RISK_NZ_MEDIUM
          ? "Medium"
          : "High";
      }
      if (totalScore <= (demolition ? DEMOLITION_RISK_VERY_LOW : RISK_VERY_LOW))
        return "Very Low";
      if (totalScore <= (demolition ? DEMOLITION_RISK_LOW : RISK_LOW)) return "Low";
      if (totalScore <= (demolition ? DEMOLITION_RISK_MODERATE : RISK_MODERATE))
        return "Moderate";
      return "High";
    },
  },
  computed: {
    ...mapGetters([
      "asbestosRecommendations",
      "asbestos",
      "room",
      "building",
      "sampledAs",
      "sampleStatuses",
      "survey",
    ]),
    item: {
      get() {
        return this.aItem;
      },
      set(updated) {
        this.$emit("update:aItem", updated);
      },
    },
    clientRecommendations() {
      let rc = this.asbestosRecommendations.filter((rec) => rec.deleted_at == null);
      return rc.filter(
        (r) => r.company_id == null || r.client_ids.indexOf(this.survey.client_id) >= 0
      );
    },
    isInaccessible() {
      return this.room.is_inaccessible || this.item.assessment_method_id == 5;
    },
    itemRecommendations: {
      get() {
        return this.item.item_recommendations;
      },
      set(updated) {
        this.item.item_recommendations = updated;
      },
    },
    materialScore() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return 0;
      return this.getMaterialScore();
    },
    priorityScore() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return 0;
      return this.getPriorityScore();
    },
    totalScore() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return 0;
      return this.getTotalScore();
    },
    riskRating() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return "Negative";
      return this.getRiskRating();
    },
  },
};
</script>
