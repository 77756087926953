<template>
  <v-col>
    <v-row>
      <v-col class="pa-0 ma-0">
        <h3 :id="`property-${property.id}`">
          {{ property.name }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-item-group
          :error-messages="errors.collect(property.name)"
          v-validate="'required'"
          :data-vv-name="property.name"
          :value="itemScore(property)"
          required
          :data-cy="`item-group-${group}`"
        >
          <v-row>
            <v-item v-for="score of property.scores" v-bind:key="score.id">
              <v-col lg="3" md="12" sm="12" cols="12" class="pa-0 ma-0">
                <v-card
                  lg="12"
                  md="12"
                  sm="12"
                  cols="12"
                  :class="
                    `d-flex align-center pa-0 mr-1 mb-1 fill-height ${scoreColour(
                      score
                    )}`
                  "
                  @click.native="addItemScoring(property.id, score.id)"
                >
                  <v-card-title>
                    <v-tooltip
                      top
                      class="top"
                      v-if="item.assessment_method_id == 2 && sampledAsItem && sampledAsScore(score.id)"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon color="warning" v-on="on" class="mt-n4 ml-n2"
                          >far fa-exclamation-triangle</v-icon
                        >
                      </template>
                      <span>Sampled as score</span>
                    </v-tooltip>
                    <p class="flex-wrap">
                      {{ score.score }}
                    </p>
                  </v-card-title>
                  <v-card-text :class="`text-wrap`">
                    <p
                      :class="
                        `mt-3 text-wrap mb-0 pb-0 ${textColour(score)}`
                      "
                    >
                      {{ score.description }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-item>
          </v-row>
        </v-item-group>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    property: {
      type: Object
    },
    group: {
      type: String
    },
    aItem: {
      type: Object
    },
    sampledAsItem: {
      type: Object
    }
  },
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  data: () => ({
    scoring: null
  }),
  computed: {
    ...mapGetters(["itemScore", "invalidInput", "asbestos"]),
    item: {
      get() {
        return this.aItem;
      },
      set(updated) {
        this.$emit("update:aItem", updated);
      }
    }
  },
  methods: {
    sampledAsScore(score) {
      if (!this.sampledAsItem) return false;
      return this.sampledAsItem.item_scores.find(
        itemScore => itemScore == score
      );
    },
    setItemScore({ property, score }) {
      let itemScores = {};
      for (let i = 0; i < 12; i++) {
        itemScores[i] = 0;
      }
      itemScores = { ...itemScores, ...this.item.item_scores };
      for (let group = 0; group < this.asbestos.length; group++) {
        let prop = this.asbestos[group].properties.find(
          ({ id }) => id == property
        );
        if (!prop) continue;
        for (let i = 0; i < prop.scores.length; i++) {
          itemScores[prop.id - 1] = score;
        }
        itemScores = Object.keys(itemScores).map(function(key) {
          return itemScores[key];
        });
        this.item.item_scores = itemScores;
      }
    },
    checkItemScore(id) {
      const score = this.item.item_scores.find(itemScore => itemScore == id);
      return score !== undefined;
    },
    scoreColour(score) {
      const result = [
        "indigo lighten-4",
        "indigo lighten-3",
        "indigo white--text",
        "indigo darken-4 white--text"
      ][score.score];
      if (this.checkItemScore(score.id)) {
        return "success white--text";
      }
      return result;
    },
    textColour({ score, id}) {
      const result = [
        "text--black",
        "text--black",
        "white--text",
        "white--text"
      ][score];
      if (this.checkItemScore(id)) {
        return "success white--text";
      }
      return result;
    },
    addItemScoring(property, score) {
      this.setItemScore({ property, score, save: true });
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        if(!this.$route.params.item || this.item.item_scores.includes(0)) {
          this.$emit("next-step");
        }
      });
    }
  }
};
</script>

<style scoped>
.score-border {
  border-bottom: 0.5em solid #ff6d00 !important;
  border-top: 0.5em solid #ff6d00 !important;
}
</style>
